import React, {useContext, useState, createContext, useEffect} from 'react'
import _isEmpty from 'lodash-es/isEmpty'
import { useApolloClient } from '@apollo/client'
import {useClientUser} from 'contexts/ClientUserProvider'
import {STORE, OPERATION_HOUR, BANKS, ADD_STORE, UPDATE_STORE, DELETE_STORE } from 'query'

const StoreContext = createContext(null);

const StoreProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const { clientUserData, setClientUserData } = useClientUser()
  const [selectedStore, setSelectedStore] = useState({})
  const [storeIsLoading, setStoreIsLoading] = useState(true);
  const [operationHour, setOperationHour] = useState(true);
  const [bankList, setBankList] = useState([]);

  const selectedStoreID = selectedStore?._id || null;

  const ProccessFlowType = [
    {label:"Pending", value:"pending", next:null},
    {label:"Processing", value:"processing", next:null},
    {label:"Confirmed", value:"confirmed", next:null},
    {label:"Completed", value:"completed", next:null},
  ]

  useEffect(() => {
    // Handle Logged In Situation
    if(!_isEmpty(clientUserData) && _isEmpty(selectedStore)) {
      const storeId = clientUserData.stores.find(item => item?._id === localStorage.getItem('@bereachStore'))
        ? localStorage.getItem('@bereachStore')
        : clientUserData.stores[0]._id;

      localStorage.setItem('@bereachStore', storeId);
      setCurrentStore(storeId)
      getBankList()
    }

    // Handle Logout Situation
    if (_isEmpty(clientUserData)) {
      setSelectedStore({});
    }
  },[clientUserData])

  const setCurrentStore = async (store_id) => {
    try{
      setStoreIsLoading(true)
      await getStoreOperationHour(store_id) 
      const store = await getCurrentStore(store_id);
      setSelectedStore(store)
      setStoreIsLoading(false)
    } catch (err){
      console.error("StoreProvider setCurrentStore", err)
      throw err
    }
  }

  const getStoreOperationHour = async(store_id) => {
    try{
      if(store_id === null || store_id ===''){
        throw 'store_id cannot be empry or null'
      }
      let result = await apolloClient.query({
        query: OPERATION_HOUR,
        variables: {store_id:store_id},
      })
      setOperationHour(result.data.operationHour)
    } catch (err){
      console.error("StoreProvider getStoreOperationHour", err)
      throw err
    }
  }

  const getBankList = async () => {
    try{
      let result = await apolloClient.query({
        query: BANKS,
        fetchPolicy: 'network-only'
      })
      setBankList(result.data.banks)
      return
    } catch (err){
      console.error("StoreProvider getBankList", err)
      throw err
    }
  }

  const getCurrentStore = async(store_id) => {
    try{
      let result = await apolloClient.query({
        fetchPolicy: "network-only",
        query: STORE,
        variables: {store_id},
      })

      return result?.data?.store || null;
    } catch (err){
      console.error("StoreProvider getCurrentStore", err)
      throw err
    }
  }

  const createStore = async(input) => {
    try{
      let result = await apolloClient.mutate({
        mutation: ADD_STORE,
        variables: {input},
      })
      const { status, payload } = result.data.addStore.payload

      if(status === 'success') insertWithNewData("create", null, payload)
      if(status !== 'success') throw result
    }catch(err){
      console.error("StoreProvider createStore", err)
      throw err
    }
  }

  const updateStore = async (idx, input) => {
    const { 
      _id, display_name, store_name, company_id, email, tel, whatsapp, website_url, 
      description, logo_image, images, currency_id, store_company_reg_no, last_bill_number, 
      order_status, xp_printer_sn, operationTime, companies, bank_id, bank_acc_no, status 
    }
    = input

    try{
      let result = await apolloClient.mutate({
        mutation: UPDATE_STORE,
        variables: {input: {
          _id, display_name, store_name, company_id, email, tel, whatsapp, website_url, 
        description, logo_image, images, currency_id, store_company_reg_no, last_bill_number, 
        order_status, xp_printer_sn, operationTime, companies, bank_id, bank_acc_no, status 
        }},
      })
      console.log(result)
      const { status: resStatus, payload } = result.data.updateStore
      
      if(resStatus === 'success') insertWithNewData("update", idx, payload)
      if(resStatus !== 'success') throw result
    }catch(err){
      console.error("StoreProvider updateStore", err)
      throw err
    }
  }

  const deleteStore = async(_id) => {
    try{
      let result = await apolloClient.mutate({
        mutation: DELETE_STORE,
        variables: {_id},
      })
      const { data, errors } = result.data
      
      if(errors) {
        throw result 
      } else {
        insertWithNewData("delete", null, {_id})
      }
    }catch(err){
      console.error("StoreProvider deleteStore", err)
      throw err
    }
  }

  const switchStore = (storeId) => {
    localStorage.setItem('@bereachStore', storeId)
    setCurrentStore(storeId)
  }

  const insertWithNewData = (type, idx, data) => {
    let values = [...clientUserData['stores']]

    if(type === "create") {
      values = [...values, data]
    }

    if(type === "update") {
      values[idx] = { ...values[idx], ...data}
    }

    if(type === "delete") {
      console.log(values)
      values = values.filter((item) => item._id !== data._id)
      console.log(values)
    }

    setClientUserData({ ...clientUserData, stores: values })
  }

  return (
    <StoreContext.Provider
      value={{
        setCurrentStore,
        operationHour,
        selectedStore,
        selectedStoreID,
        bankList,
        storeIsLoading,
        ProccessFlowType,
        getCurrentStore,
        getStoreOperationHour,
        createStore,
        updateStore,
        deleteStore,
        switchStore,
      }}>
      {children}
    </StoreContext.Provider>
  );
};

const useStore = () => {
  const store = useContext(StoreContext);
  if (store == null) {
    throw new Error('useStore() called outside of a StoreProvider?');
  }
  return store;
};

export {StoreProvider, useStore};


