import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useClientUser } from 'contexts/ClientUserProvider'
import LoadingScreen from 'components/Common/LoadingScreen';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));
const Register = React.lazy(() => import('./views/register/Register'));

const App = () =>  {
  const { isLoggedIn, mainLoading } = useClientUser()
  
  if (mainLoading) {
    return <LoadingScreen />
  }
  
    return (
    <BrowserRouter>
        <Suspense fallback={<LoadingScreen />}>
        {isLoggedIn 
        ? 
          <Switch>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
        :
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
          <Redirect to="/login" />
        </Switch>
        }   
      </Suspense>
    </BrowserRouter>
  );
}

export default App;